@use "../plugins/mq" as *;
@use "../framework/mixins" as *;
@use "../framework/variables" as *;

body {
  background-color: var(--primary);
  color: #665531;
}

.page--title, .article-body {
  color: currentColor;
}

.article-body {
  h2, .h2 {
    font-size: resp(80);

    @include mq($until: mobile) {
      font-size: 8rem;
    }
  }
}

.home {

  main {
    @include mq($until: mobile) {
      padding-top: 0;
    }
  }

  .section--fullsize_image {
    &:first-child {
      .image--wrapper {
        padding: 0;

        .media-image, picture, img {
          width: 100%;
          height: 100%;
          flex: none;
          object-fit: cover;
        }
      }

      @include mq($until: mobile) {
        min-height: 60vh;

        img {
          min-height: 60vh;
        }
      }
    }
  }

  .section {
    h1 {
      text-transform: none;
      font-family: 'Nexa Rust Script B-Trial Shadow 2', cursive, sans-serif;
    }
    .text--wrapper {
      padding-left: resp(200);
      padding-right: resp(200);
      background-color: transparent;
      color: #665531;

      a[target=_blank] {
        color: #665531;
        background-color: transparent;
        border-color: var(--primary);
        font-weight: normal;

        &:hover {
          background-color: transparent;
          color: currentColor;
          text-decoration: underline;
          text-decoration-thickness: from-font;
          text-underline-position: from-font;
          opacity: .7;
        }
      }
    }

    &.image--padding {
      .image--wrapper {
        @include mq($from: mobile) {
          padding: 0 resp(40);
        }

        @include mq($until: mobile){
          padding: 0;
        }
      }
    }
  }

  .section--text-image {
    &.rtl {
      .container {
        @include mq($until: mobile) {
          flex-direction: column;
        }
      }
    }

    .image--wrapper {
      @include mq($from: mobile) {
        width: 50%;
      }

      > div {
        &.aos-animate {
          picture:after {
            scale: 1 1;
            opacity: 1;
          }
        }
      }
    }

    .text--wrapper {
      position: relative;

      img {
        position: absolute;

        @include mq($until: mobile) {
          scale: .4
        }
      }
    }

    &:nth-of-type(2) {
      .text--wrapper {
        position: static;

        img {
          bottom: 0;
          left: 0;
          rotate: 45deg
        }
      }
    }

    &:nth-of-type(3) {
      .text--wrapper {
        background-color: #665531;

        * {
          color: var(--secondary) !important;
        }

        img {
          right: resp(-50);
          rotate: -45deg;

          @include mq($from: mobile) {
            scale: .8;
          }

          @include mq($until: mobile) {
            top: 0;
            bottom: initial;
          }
        }
      }
    }

    &:nth-of-type(4) {
      .text--wrapper {
        background-color: var(--secondary);

        img {
          position: relative;

          @include mq($until: mobile) {
            scale: 1;
          }
        }
      }
    }
  }
}
