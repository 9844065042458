@use "../framework/mixins" as *;
@use "../plugins/mq" as *;
@font-face {
  font-family: 'Edwardian Script ITC';
  src: url('../../fonts/EdwardianScriptITC.woff2') format('woff2'),
  url('../../fonts/EdwardianScriptITC.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kudryashev Display';
  src: url('../../fonts/KudryashevDisplay.woff2') format('woff2'),
  url('../../fonts/KudryashevDisplay.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



body {
  font-family: 'Kudryashev Display', serif;
}

p {
  font-size: resp(36);
  line-height: 1.4;

  @include mq($until: mobile) {
    font-size: 2rem;
  }
}

h1, .h1 {
  font-family: 'Edwardian Script ITC', cursive, sans-serif;
  text-transform: none;
  color: var(--primary);
  font-weight: normal;
  font-size: resp(100);

  @include mq($until: mobile) {
    font-size: 4rem;
  }
}

h2, .h2 {
  font-family: 'Edwardian Script ITC', cursive, sans-serif;
  text-transform: none;
  font-size: resp(80);

  @include mq($until: mobile) {
    font-size: 8rem;
  }
}

.home {
  .section {
    .button--action, .text--wrapper a[target=_blank] {
      font-family: 'Edwardian Script ITC', cursive, sans-serif;
      font-weight: normal;
      text-transform: none;
      letter-spacing: 2px;
      font-size: resp(80);
      border: none;

      @include mq($until: mobile) {
        font-size: 8rem;
      }
    }
  }
}
