@use '../framework/mixins' as *;
@use '../plugins/mq' as *;

.main--header {
  display: none;
  height: auto;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header--logo {
    display: flex;
    justify-content: center;

    img {
      @include mq($from: mobile) {
        max-width: resp(500);
      }
    }
  }
}
