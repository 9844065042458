@use "_mixins" as *;

:root {
  --black: #000;
  --white: #fff;
  --grey: #959595;

  --bg: #FBECD9;

  --font: "Open Sans", serif;
  --fontColor: var(--primary);
  --fontColor2: var(--secondary);

  --book: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --bolder: 900;
  --borderColor: #DDDDDD;

  --containerWidth: 1340;
}
