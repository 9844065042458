@use '../framework/mixins' as *;
@use '../plugins/mq' as *;

.main--footer {
  border: none;
  background-color: var(--secondary);
  color: currentColor;

  h2 {
    @include mq($from: mobile) {
      font-size: resp(80);
    }
  }
}

.button--insta {
  color: currentColor;

  svg {
    * {
      fill: currentColor;
    }
  }
}

.footer--navigation {
  background-color: #665531;
}
