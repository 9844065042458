.main--header {
  display: none;
  height: auto;
}
.main--header ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main--header .header--logo {
  display: flex;
  justify-content: center;
}
@media (min-width: 64em) {
  .main--header .header--logo img {
    max-width: 26.0416666667vw;
  }
}

.main--footer {
  border: none;
  background-color: var(--secondary);
  color: currentColor;
}
@media (min-width: 64em) {
  .main--footer h2 {
    font-size: 4.1666666667vw;
  }
}

.button--insta {
  color: currentColor;
}
.button--insta svg * {
  fill: currentColor;
}

.footer--navigation {
  background-color: #665531;
}

@font-face {
  font-family: "Edwardian Script ITC";
  src: url("../../fonts/EdwardianScriptITC.woff2") format("woff2"), url("../../fonts/EdwardianScriptITC.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kudryashev Display";
  src: url("../../fonts/KudryashevDisplay.woff2") format("woff2"), url("../../fonts/KudryashevDisplay.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Kudryashev Display", serif;
}

p {
  font-size: 1.875vw;
  line-height: 1.4;
}
@media (max-width: 63.99em) {
  p {
    font-size: 2rem;
  }
}

h1, .h1 {
  font-family: "Edwardian Script ITC", cursive, sans-serif;
  text-transform: none;
  color: var(--primary);
  font-weight: normal;
  font-size: 5.2083333333vw;
}
@media (max-width: 63.99em) {
  h1, .h1 {
    font-size: 4rem;
  }
}

h2, .h2 {
  font-family: "Edwardian Script ITC", cursive, sans-serif;
  text-transform: none;
  font-size: 4.1666666667vw;
}
@media (max-width: 63.99em) {
  h2, .h2 {
    font-size: 8rem;
  }
}

.home .section .button--action, .home .section .text--wrapper a[target=_blank] {
  font-family: "Edwardian Script ITC", cursive, sans-serif;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 2px;
  font-size: 4.1666666667vw;
  border: none;
}
@media (max-width: 63.99em) {
  .home .section .button--action, .home .section .text--wrapper a[target=_blank] {
    font-size: 8rem;
  }
}

:root {
  --black: #000;
  --white: #fff;
  --grey: #959595;
  --bg: #FBECD9;
  --font: "Open Sans", serif;
  --fontColor: var(--primary);
  --fontColor2: var(--secondary);
  --book: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --bolder: 900;
  --borderColor: #DDDDDD;
  --containerWidth: 1340;
}

body {
  background-color: var(--primary);
  color: #665531;
}

.page--title, .article-body {
  color: currentColor;
}

.article-body h2, .article-body .h2 {
  font-size: 4.1666666667vw;
}
@media (max-width: 63.99em) {
  .article-body h2, .article-body .h2 {
    font-size: 8rem;
  }
}

@media (max-width: 63.99em) {
  .home main {
    padding-top: 0;
  }
}
.home .section--fullsize_image:first-child .image--wrapper {
  padding: 0;
}
.home .section--fullsize_image:first-child .image--wrapper .media-image, .home .section--fullsize_image:first-child .image--wrapper picture, .home .section--fullsize_image:first-child .image--wrapper img {
  width: 100%;
  height: 100%;
  flex: none;
  object-fit: cover;
}
@media (max-width: 63.99em) {
  .home .section--fullsize_image:first-child {
    min-height: 60vh;
  }
  .home .section--fullsize_image:first-child img {
    min-height: 60vh;
  }
}
.home .section h1 {
  text-transform: none;
  font-family: "Nexa Rust Script B-Trial Shadow 2", cursive, sans-serif;
}
.home .section .text--wrapper {
  padding-left: 10.4166666667vw;
  padding-right: 10.4166666667vw;
  background-color: transparent;
  color: #665531;
}
.home .section .text--wrapper a[target=_blank] {
  color: #665531;
  background-color: transparent;
  border-color: var(--primary);
  font-weight: normal;
}
.home .section .text--wrapper a[target=_blank]:hover {
  background-color: transparent;
  color: currentColor;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-position: from-font;
  opacity: 0.7;
}
@media (min-width: 64em) {
  .home .section.image--padding .image--wrapper {
    padding: 0 2.0833333333vw;
  }
}
@media (max-width: 63.99em) {
  .home .section.image--padding .image--wrapper {
    padding: 0;
  }
}
@media (max-width: 63.99em) {
  .home .section--text-image.rtl .container {
    flex-direction: column;
  }
}
@media (min-width: 64em) {
  .home .section--text-image .image--wrapper {
    width: 50%;
  }
}
.home .section--text-image .image--wrapper > div.aos-animate picture:after {
  scale: 1 1;
  opacity: 1;
}
.home .section--text-image .text--wrapper {
  position: relative;
}
.home .section--text-image .text--wrapper img {
  position: absolute;
}
@media (max-width: 63.99em) {
  .home .section--text-image .text--wrapper img {
    scale: 0.4;
  }
}
.home .section--text-image:nth-of-type(2) .text--wrapper {
  position: static;
}
.home .section--text-image:nth-of-type(2) .text--wrapper img {
  bottom: 0;
  left: 0;
  rotate: 45deg;
}
.home .section--text-image:nth-of-type(3) .text--wrapper {
  background-color: #665531;
}
.home .section--text-image:nth-of-type(3) .text--wrapper * {
  color: var(--secondary) !important;
}
.home .section--text-image:nth-of-type(3) .text--wrapper img {
  right: -2.6041666667vw;
  rotate: -45deg;
}
@media (min-width: 64em) {
  .home .section--text-image:nth-of-type(3) .text--wrapper img {
    scale: 0.8;
  }
}
@media (max-width: 63.99em) {
  .home .section--text-image:nth-of-type(3) .text--wrapper img {
    top: 0;
    bottom: initial;
  }
}
.home .section--text-image:nth-of-type(4) .text--wrapper {
  background-color: var(--secondary);
}
.home .section--text-image:nth-of-type(4) .text--wrapper img {
  position: relative;
}
@media (max-width: 63.99em) {
  .home .section--text-image:nth-of-type(4) .text--wrapper img {
    scale: 1;
  }
}